import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {withRouter} from '@computerrock/formation-router';
import {withTranslations} from '@computerrock/formation-i18n';
import {invoiceSubmissionViews} from './constants/invoiceSubmissionViews';
import MemberFormWrapper from './view-elements/MemberFormWrapper';
import CaseMessagesView from '../messages/CaseMessagesView';
import {ButtonPrimary, ButtonSecondary, HoverIcon, Input, PopUp, Ribbon, Checkbox, Icon} from '../ui-components';
import * as invoiceSubmissionActionTypes from './invoiceSubmissionActionTypes';
import MemberDetailRow from './view-elements/rows/MemberDetailRow';
import SummaryDataCell from './view-elements/rows/SummaryDataCell';
import {BillingTypes} from './constants/billingTypes';
import {getMainServiceNameForType, isMainServiceSelected} from './utils/mainServices';
import Tooltip from '../ui-components/tooltip/Tooltip';
import InfoIcon from '../ui-components/assets/icons/info-red.svg';
import {convertNumberToCurrency} from './utils/conversion';
import {DEFAULT_DATE_FORMAT} from './constants/dates';
import {submitPopUpStatuses} from './constants/invoiceSubmissionStatuses';
import VehicleForm from './view-elements/VehicleForm';
import SummaryDetailRow from './view-elements/rows/SummaryDetailRow';
import {formAnchors} from './constants/formAnchors';
import {assembleAddressString} from '../utils/address';
import {InvoiceSubmissionDataStates} from './constants/invoiceSubmissionDataStates';
import './SummaryView.scss';
import {eafMainServices, eafStatuses, JourneyContinuation, UserRoles} from '../ella-dispo-entity-types';
import {EmptyTripReasons} from './constants/emptyTrip';
import {getInvoiceSubmissionValidationErrorObject} from '../utils/validationMessages';

class SummaryView extends React.Component {
    static propTypes = {
        invoiceSubmission: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        updateInvoiceSubmission: PropTypes.func.isRequired,
        downloadProofOfService: PropTypes.func.isRequired,
        isInvoiceSubmissionSubmitted: PropTypes.bool.isRequired,
        isInvoiceSubmissionStatusRequest: PropTypes.bool.isRequired,
        invoiceSubmissionMessages: PropTypes.array,
        submitInvoiceSubmission: PropTypes.func.isRequired,
        changeView: PropTypes.func.isRequired,
        invoiceSubmissionViewState: PropTypes.string,
        isInvoiceSubmissionRequestedSubService: PropTypes.bool,
        createFollowUpAssignment: PropTypes.func.isRequired,
        downloadPdfAndCloseSubmitPopUp: PropTypes.func.isRequired,
        errors: PropTypes.object,
        invoiceSubmissionDataState: PropTypes.string.isRequired,
        openCarMaxPrice: PropTypes.number,
        closeSubmitPopUp: PropTypes.func.isRequired,
        submitPopUpStatus: PropTypes.string.isRequired,
        deleteErrors: PropTypes.func.isRequired,
        dataStatus: PropTypes.string.isRequired,
        userAccount: PropTypes.object,
        translate: PropTypes.func,
        downloadBCReceiptPDF: PropTypes.func.isRequired,
    };

    static defaultProps = {
        invoiceSubmissionMessages: [],
        invoiceSubmissionViewState: '',
        isInvoiceSubmissionRequestedSubService: false,
        errors: {},
        openCarMaxPrice: 0,
        userAccount: null,
        translate: null,
    };

    constructor(props) {
        super(props);
        const {externalDocumentNo} = props.invoiceSubmission;
        const {isInvoiceSubmissionStatusRequest} = props;
        this.state = {
            isCheckedMemberCertificate: isInvoiceSubmissionStatusRequest || false,
            externalDocumentNo: externalDocumentNo || '',
            isExternalDocumentNoLoaded: false,
        };
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
    };

    componentDidUpdate = prevProps => {
        if (
            this.props.invoiceSubmission?.externalDocumentNo
            && !prevProps.invoiceSubmission?.externalDocumentNo
            && !this.state.isExternalDocumentNoLoaded
        ) {
            this.setState({
                externalDocumentNo: this.props.invoiceSubmission?.externalDocumentNo,
                isExternalDocumentNoLoaded: true,
            });
        }
    };

    settingOutputString = (first, second) => {
        return first && second ? `${first} ${second}` : '-';
    };

    settingDriverOwnerData = (type, memberData, data) => {
        const output = type === 'MEMBER' ? memberData : data;
        return output || '-';
    };

    isSpecificEmptyTrip = () => {
        const {emptyTripReason, journeyContinuation} = this.props.invoiceSubmission;
        if (journeyContinuation !== JourneyContinuation.EMPTY_TRIP) {
            return false;
        }
        return [
            EmptyTripReasons.ORDER_CANCELED.type,
            EmptyTripReasons.NO_LOAD_CUSTOMER_NO_LONGER_ON_SITE.type,
            EmptyTripReasons.INCORRECT_LOCATION.type,
        ].indexOf(emptyTripReason) !== -1;
    };

    onChangeMemberCertificate = () => {
        this.setState(prevState => {
            return {
                isCheckedMemberCertificate: !prevState.isCheckedMemberCertificate,
            };
        });
    };

    onExternalDocumentNoChange = data => {
        this.setState({
            externalDocumentNo: data.value,
        });
    };

    isCaseBookedOrPayed = () => {
        const {status} = this.props.invoiceSubmission;
        return [eafStatuses.BOOKED, eafStatuses.PAID].indexOf(status) !== -1;
    };

    createFollowUpAssignment = () => {
        const {invoiceSubmissionId} = this.props.invoiceSubmission;
        this.props.createFollowUpAssignment({invoiceSubmissionId});
    };

    onSubmitButtonClick = () => {
        const {invoiceSubmission, isInvoiceSubmissionSubmitted, isInvoiceSubmissionStatusRequest} = this.props;
        const {history} = this.props;
        const {externalDocumentNo} = this.state;
        const {attachments} = invoiceSubmission;

        if (this.isCaseBookedOrPayed()) {
            const attachment = attachments.find(attachment => !!attachment.isReceipt);
            if (attachment) {
                this.props.downloadBCReceiptPDF({
                    fileName: attachment.fileName,
                    url: attachment.url,
                });
                return;
            }
        }

        if (isInvoiceSubmissionSubmitted && !isInvoiceSubmissionStatusRequest) {
            history.goBack();
            return;
        }

        this.props.submitInvoiceSubmission({
            invoiceSubmissionId: invoiceSubmission.invoiceSubmissionId,
            externalDocumentNumber: externalDocumentNo,
        });
    };

    shouldDisableSubmitButton = () => {
        const {isCheckedMemberCertificate} = this.state;
        const {
            isInvoiceSubmissionSubmitted, isInvoiceSubmissionStatusRequest, dataStatus,
            invoiceSubmission,
        } = this.props;
        if (dataStatus === InvoiceSubmissionDataStates.UPDATING
            || dataStatus === InvoiceSubmissionDataStates.DOWNLOADING) {
            return true;
        }

        const isBookedOrPayed = this.isCaseBookedOrPayed();

        if (!isBookedOrPayed) {
            if (isInvoiceSubmissionStatusRequest) {
                return !isCheckedMemberCertificate;
            }
            if ((isInvoiceSubmissionSubmitted && !isInvoiceSubmissionStatusRequest) || this.isSpecificEmptyTrip()) {
                return false;
            }
        }
        if (isBookedOrPayed) {
            return !invoiceSubmission.attachments.find(attachment => !!attachment.isReceipt);
        }
        return !isCheckedMemberCertificate;
    };

    onSaveButtonClick = () => {
        const {invoiceSubmission, isInvoiceSubmissionSubmitted} = this.props;
        const {history} = this.props;
        const {externalDocumentNo} = this.state;
        if (!isInvoiceSubmissionSubmitted) {
            this.props.updateInvoiceSubmission({
                invoiceSubmissionId: invoiceSubmission.invoiceSubmissionId,
                assignmentId: invoiceSubmission.assignmentId,
                data: {
                    ...invoiceSubmission,
                    externalDocumentNo,
                },
                isNoValidation: true,
            });
            return;
        }
        history.goBack();
    };

    render() {
        const {
            invoiceSubmissionViewState, invoiceSubmissionMessages, openCarMaxPrice, errors,
            closeSubmitPopUp, userAccount,
        } = this.props;
        const {isInvoiceSubmissionSubmitted, invoiceSubmissionDataState, invoiceSubmission} = this.props;
        const {isInvoiceSubmissionStatusRequest, isInvoiceSubmissionRequestedSubService} = this.props;
        const {
            changeView, submitPopUpStatus, deleteErrors, downloadPdfAndCloseSubmitPopUp,
            downloadProofOfService,
        } = this.props;
        const {translate} = this.props;
        const {
            vpName, invoiceSubmissionId, membershipNumber, memberFullName, memberAddressPostCode, memberAddressCity,
            memberAddressStreet, memberAddressCountry, driverType, driverName, driverAddressStreet,
            driverAddressPostCode, driverAddressCity, driverAddressCountry, ownerType, ownerName, ownerAddressStreet,
            ownerAddressPostCode, ownerAddressCity, ownerAddressCountry, name, startDate, mainServices, billingType,
            isForeignInsurance, journeyContinuation, additionalServices, status, vehicleManufacturer, vehicleModel,
            vehicleWeight, licensePlate, registrationDateTime, vehicleType, odometer, endDate, damageAddressStreet,
            damageCity, damagePostCode, damageCountry, mainServicesComment, decodedMainServices, towingAddressStreet,
            towingAddressPostCode, towingAddressCity, towingAddressCountry, towingDestinationType, pickUpAddressStreet,
            pickUpAddressPostCode, pickUpAddressCity, pickUpAddressCountry, pickUpDestinationType,
            additionalServicesComment, attachments, isFollowUpInvoiceSubmissionAllowed, total,
            fallbackSystemId,
        } = invoiceSubmission;
        const {externalDocumentNo, isCheckedMemberCertificate} = this.state;
        const isMemberFormDisplayed = invoiceSubmissionViewState === invoiceSubmissionViews.MEMBER_FORM;
        const isVehicleFormDisplayed = invoiceSubmissionViewState === invoiceSubmissionViews.VEHICLE_FORM;
        const isAccordionOpen = invoiceSubmissionMessages?.length >= 0;
        const memberCountry = memberAddressCountry
            ? translate(`global.countries.${memberAddressCountry.toLowerCase()}`)
            : null;
        const driverCountry = driverAddressCountry
            ? translate(`global.countries.${driverAddressCountry.toLowerCase()}`)
            : null;
        const ownerCountry = ownerAddressCountry
            ? translate(`global.countries.${ownerAddressCountry.toLowerCase()}`)
            : null;
        const memberCityPostCode = this.settingOutputString(memberAddressPostCode, memberAddressCity);
        const driverDisplayName = this.settingDriverOwnerData(driverType, memberFullName, driverName);
        const driverDisplayAddress = this.settingDriverOwnerData(driverType, memberAddressStreet, driverAddressStreet);
        const driverDisplayCity = this.settingDriverOwnerData(driverType, memberAddressCity, driverAddressCity);
        const driverDisplayPostCode = this.settingDriverOwnerData(driverType, memberAddressPostCode, driverAddressPostCode); // eslint-disable-line
        const driverCityPostCode = this.settingOutputString(driverDisplayPostCode, driverDisplayCity);
        const driverDisplayCountry = this.settingDriverOwnerData(driverType, memberCountry, driverCountry);
        const ownerDisplayCountry = this.settingDriverOwnerData(ownerType, memberCountry, ownerCountry);
        const ownerDisplayName = this.settingDriverOwnerData(ownerType, memberFullName, ownerName);
        const ownerDisplayAddress = this.settingDriverOwnerData(ownerType, memberAddressStreet, ownerAddressStreet);
        const ownerDisplayCity = this.settingDriverOwnerData(ownerType, memberAddressCity, ownerAddressCity);
        const ownerDisplayPostCode = this.settingDriverOwnerData(ownerType, memberAddressPostCode, ownerAddressPostCode); //eslint-disable-line
        const ownerCityPostCode = this.settingOutputString(ownerDisplayPostCode, ownerDisplayCity);
        const startDateString = startDate ? startDate.format(DEFAULT_DATE_FORMAT) : '-';
        const startTime = startDate ? `${startDate.format('DD.MM.YYYY - HH:mm')} Uhr` : '-';
        const endTime = endDate ? `${endDate.format('DD.MM.YYYY - HH:mm')} Uhr` : '-';
        const isEmptyTrip = journeyContinuation === JourneyContinuation.EMPTY_TRIP;
        const isInvoiceIssued = status === 'PAID' || status === 'BOOKED';
        const buttonText = isInvoiceIssued ? translate('summary_view.button_label.download')
            : (isInvoiceSubmissionSubmitted && !isInvoiceSubmissionStatusRequest) ? translate('summary_view.button_label.leaving') : translate('summary_view.button_label.apply_for');
        const vehicleModelString = this.settingOutputString(vehicleManufacturer, vehicleModel);
        const vehicleLicensePlateShow = licensePlate ? licensePlate.replace(/^(.*)-$/, '$1') : '-';
        const vehicleRegistrationDate = registrationDateTime ? registrationDateTime.year() : '-';
        const damageAddressString = assembleAddressString({
            street: damageAddressStreet,
            postalCode: damagePostCode,
            city: damageCity,
            country: damageCountry,
        });
        const towingDestinationAddress = assembleAddressString({
            street: towingAddressStreet,
            postalCode: towingAddressPostCode,
            city: towingAddressCity,
            country: towingAddressCountry,
        });
        const pickUpDestinationAddress = assembleAddressString({
            street: pickUpAddressStreet,
            postalCode: pickUpAddressPostCode,
            city: pickUpAddressCity,
            country: pickUpAddressCountry,
        });
        const isSpecificEmptyTrip = !this.isSpecificEmptyTrip();
        const isPickUpSelected = isMainServiceSelected(decodedMainServices, eafMainServices.PICKUP);
        const isTowingSelected = isMainServiceSelected(decodedMainServices, eafMainServices.TOWING);
        const filteredAdditionalServices = additionalServices.filter(({type, subtotal}) => {
            return translate(`global.additional_services.${type.toLowerCase()}`) !== 'Tonnagezuschlag' && subtotal !== 0;
        });
        const saveButtonText = isInvoiceSubmissionSubmitted ? translate('summary_view.button_label.leaving') : translate('summary_view.button_label.save');
        const overviewDataSectionClassName = classNames('overview-data', {
            'overview-data--is-transparent': isMemberFormDisplayed || isVehicleFormDisplayed,
        });
        const overviewPriceClassName = classNames('overview-price', {
            'overview-price--is-not-visible': isMemberFormDisplayed || isVehicleFormDisplayed,
        });
        const overviewPriceSaveClassName = classNames('overview-price__save-button-container', {
            'overview-price__save-button-container--is-transparent': isMemberFormDisplayed || isVehicleFormDisplayed,
        });

        const submitInvoiceSubmissionValidationObject = submitPopUpStatus === submitPopUpStatuses.VALIDATION_FAILED
            ? getInvoiceSubmissionValidationErrorObject(errors) : null;

        const allowMessageFromNav = userAccount && typeof userAccount === 'object' && Object.keys(userAccount).length
            && userAccount.userRoles.includes(UserRoles.NAVISION)
            && (
                invoiceSubmission.status === eafStatuses.CREDITING_REQUESTED
                || invoiceSubmission.status === eafStatuses.BOOKED
                || invoiceSubmission.status === eafStatuses.PAID
                || invoiceSubmission.status === eafStatuses.EXTERNAL_INVOICING
            );

        return (
            <div className="overview-forms-container">
                {submitPopUpStatus === submitPopUpStatuses.SUCCESS && (
                    <PopUp>
                        <div className="col-start-xs--3 col-xs--8 ace-bottom-margin--xl">
                            <h3 className="ace-h3 ace-h3--center ace-bottom-margin--xl">
                                {translate('summary_view.header.application_submitted_successfully')}
                            </h3>
                            <p className="ace-copy-m ace-copy-m--center">
                                {`${translate('summary_view.text.application_with_number')} ${invoiceSubmission.name} ${translate('summary_view.text.sent_to_ace_review')}`}
                            </p>
                        </div>
                        <div className="col-xs--4 col-start-xs--3">
                            <ButtonSecondary
                                name="download"
                                label={translate('summary_view.button_label.download_certificate')}
                                onClick={() => downloadPdfAndCloseSubmitPopUp({invoiceSubmissionId})}
                            />
                        </div>
                        <div className="col-xs--4">
                            <ButtonPrimary
                                name="exit"
                                label={translate('summary_view.button_label.exit')}
                                onClick={closeSubmitPopUp}
                            />
                        </div>
                    </PopUp>
                )}
                {submitPopUpStatus === submitPopUpStatuses.VALIDATION_FAILED && (
                    <PopUp>
                        <div className="col-start-xs--3 col-xs--8 ace-bottom-margin--lg">
                            {Object.keys(submitInvoiceSubmissionValidationObject)
                                .filter(errorType => !!submitInvoiceSubmissionValidationObject[errorType].length)
                                .map((errorType, idx) => {
                                    return (
                                        <Fragment key={`${errorType}-${idx}`}>
                                            <h3 className="ace-h3 ace-h3--center ace-bottom-margin--sm">
                                                {translate(`global.submit_validation_title.${errorType.replace(/[A-Z]/g, c => {
                                                    return '_' + c.toLowerCase();
                                                })}`)}
                                            </h3>
                                            <p className="ace-copy-m">
                                                {translate('summary_view.error_message_text.data_incomplete')}
                                            </p>
                                            <ul className="overview-validation-list ace-bottom-margin--sm">
                                                {submitInvoiceSubmissionValidationObject[errorType].map(error => (
                                                    <li key={error}>{error}</li>
                                                ))}
                                            </ul>
                                        </Fragment>
                                    );
                                })}
                        </div>
                        <div className="col-start-xs--5 col-xs--4">
                            <ButtonPrimary
                                name="cancelPopUp"
                                label={translate('summary_view.button_label.cancel')}
                                onClick={deleteErrors}
                            />
                        </div>
                    </PopUp>
                )}
                <div className="ace-grid__row">
                    <div
                        className={isMemberFormDisplayed || isVehicleFormDisplayed
                            ? 'col-start-xs--1 col-xs--11' : 'col-start-xs--1 col-xs--7'}
                    >
                        {/* left side table */}
                        {isMemberFormDisplayed ? (
                            <MemberFormWrapper invoiceSubmission={invoiceSubmission} />
                        ) : (
                            <div className="ace-grid__row">
                                <div className={`${overviewDataSectionClassName} overview-data--first col-xs--7`}>
                                    <CaseMessagesView
                                        messages={invoiceSubmissionMessages}
                                        partnerName={vpName}
                                        invoiceSubmissionId={invoiceSubmissionId}
                                        isMessageInputFieldShown={isInvoiceSubmissionStatusRequest
                                            || invoiceSubmission.status === eafStatuses.PAYMENT_DECLINED
                                            || allowMessageFromNav}
                                        isAccordionOpen={isAccordionOpen}
                                    />
                                    <div className="overview-data__title overview-data__title--first">
                                        <h4 className="ace-h4">
                                            {translate('summary_view.title.member_data')}
                                        </h4>
                                        <HoverIcon
                                            icon="pen"
                                            isDisabled={isMemberFormDisplayed
                                                || isVehicleFormDisplayed
                                                || isInvoiceSubmissionRequestedSubService}
                                            hoverText={translate('summary_view.hover_text.to_edit')}
                                            isInvisible={isInvoiceSubmissionSubmitted
                                                && !isInvoiceSubmissionStatusRequest}
                                            onClick={() => changeView({
                                                invoiceSubmissionViewState: invoiceSubmissionViews.MEMBER_FORM,
                                            })}
                                        />
                                    </div>
                                    <div className="overview-data__content">
                                        <MemberDetailRow label={translate('summary_view.data_row_label.member')}>
                                            <SummaryDataCell>{membershipNumber || '-'}</SummaryDataCell>
                                            <SummaryDataCell>{memberFullName || '-'}</SummaryDataCell>
                                            <SummaryDataCell>
                                                {memberAddressStreet || '-'}, {memberCityPostCode}, {memberCountry}
                                            </SummaryDataCell>
                                        </MemberDetailRow>
                                        <MemberDetailRow label={translate('summary_view.data_row_label.driver')}>
                                            <SummaryDataCell>{driverDisplayName}</SummaryDataCell>
                                            <SummaryDataCell>{driverDisplayAddress}</SummaryDataCell>
                                            <SummaryDataCell>
                                                {driverCityPostCode}, {driverDisplayCountry}
                                            </SummaryDataCell>
                                        </MemberDetailRow>
                                        <MemberDetailRow label={translate('summary_view.data_row_label.owner')}>
                                            <SummaryDataCell>{ownerDisplayName}</SummaryDataCell>
                                            <SummaryDataCell>{ownerDisplayAddress}</SummaryDataCell>
                                            <SummaryDataCell>
                                                {ownerCityPostCode}, {ownerDisplayCountry}
                                            </SummaryDataCell>
                                        </MemberDetailRow>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isVehicleFormDisplayed && (
                            <VehicleForm invoiceSubmission={invoiceSubmission} />
                        )}
                        {!isVehicleFormDisplayed && (
                            <div className="ace-grid__row">
                                <div className={`${overviewDataSectionClassName} overview-data--grey col-xs--7`}>
                                    <Ribbon color="grey" width="30px" />
                                    <div className="ace-h4 overview-data__title">
                                        <h4 className="ace-h4">{translate('summary_view.section_title.vehicle_data')}</h4>
                                        <HoverIcon
                                            icon="pen"
                                            isDisabled={isMemberFormDisplayed
                                                || isVehicleFormDisplayed
                                                || isInvoiceSubmissionRequestedSubService}
                                            hoverText={translate('summary_view.hover_text.to_edit')}
                                            isInvisible={isInvoiceSubmissionSubmitted
                                                && !isInvoiceSubmissionStatusRequest}
                                            onClick={() => changeView({
                                                invoiceSubmissionViewState: invoiceSubmissionViews.VEHICLE_FORM,
                                            })}
                                        />
                                    </div>
                                    <div className="overview-data__content--block">
                                        <SummaryDetailRow label={translate('summary_view.summary_detail_row_label.model')}>
                                            <SummaryDataCell>{vehicleModelString}</SummaryDataCell>
                                        </SummaryDetailRow>
                                        <SummaryDetailRow label={translate('summary_view.summary_detail_row_label.vehicle_weight')}>
                                            <SummaryDataCell>
                                                {vehicleWeight
                                                    ? translate(`global.vehicle_tonnages.${vehicleWeight.toLowerCase()}`)
                                                    : '-'}
                                            </SummaryDataCell>
                                        </SummaryDetailRow>
                                        <SummaryDetailRow label={translate('summary_view.summary_detail_row_label.licence_plate')}>
                                            <SummaryDataCell>{vehicleLicensePlateShow}</SummaryDataCell>
                                        </SummaryDetailRow>
                                        <SummaryDetailRow label={translate('summary_view.summary_detail_row_label.vehicle_registration')}>
                                            <SummaryDataCell>{vehicleRegistrationDate || '-'}</SummaryDataCell>
                                        </SummaryDetailRow>
                                        <SummaryDetailRow label={translate('summary_view.summary_detail_row_label.vehicle_type')}>
                                            <SummaryDataCell>{vehicleType || '-'}</SummaryDataCell>
                                        </SummaryDetailRow>
                                        <SummaryDetailRow label={translate('summary_view.summary_detail_row_label.odometer')}>
                                            <SummaryDataCell>{odometer || '-'}</SummaryDataCell>
                                        </SummaryDetailRow>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="ace-grid__row">
                            <div className={`col-xs--7 ${overviewDataSectionClassName}`}>
                                <div className="overview-data__title">
                                    <h4 className="ace-h4">{translate('summary_view.section_title.case_data')}</h4>
                                    <HoverIcon
                                        icon="pen"
                                        isDisabled={isMemberFormDisplayed || isVehicleFormDisplayed}
                                        hoverText={translate('summary_view.hover_text.to_edit')}
                                        isInvisible={isInvoiceSubmissionSubmitted && !isInvoiceSubmissionStatusRequest}
                                        onClick={() => this.props.changeView({
                                            invoiceSubmissionViewState: invoiceSubmissionViews.FORM,
                                            formAnchor: formAnchors.FIRST_FORM,
                                        })}
                                    />
                                </div>
                                <div className="overview-data__content--block">
                                    <SummaryDetailRow label={translate('summary_view.data_row_label.period_of_use')}>
                                        <SummaryDataCell />
                                    </SummaryDetailRow>
                                    <SummaryDetailRow label={translate('summary_view.data_row_label.start')}>
                                        <SummaryDataCell>{startTime}</SummaryDataCell>
                                    </SummaryDetailRow>
                                    <SummaryDetailRow label={translate('summary_view.data_row_label.end')}>
                                        <SummaryDataCell>{endTime}</SummaryDataCell>
                                    </SummaryDetailRow>
                                    <SummaryDetailRow label={translate('summary_view.data_row_label.damage_address')}>
                                        <SummaryDataCell>{damageAddressString}</SummaryDataCell>
                                    </SummaryDetailRow>
                                    <SummaryDetailRow label={translate('summary_view.data_row_label.journey_continuation')}>
                                        <SummaryDataCell>
                                            {journeyContinuation ? translate(`global.journey_continuation.${journeyContinuation.toLowerCase()}`) : '-'}
                                        </SummaryDataCell>
                                    </SummaryDetailRow>
                                    <SummaryDetailRow label={translate('summary_view.data_row_label.billing_type')}>
                                        <SummaryDataCell>
                                            {billingType ? translate(`global.billing_type.${billingType.toLowerCase()}`) : '-'}
                                        </SummaryDataCell>
                                    </SummaryDetailRow>
                                </div>
                            </div>
                        </div>
                        <div className="ace-grid__row">
                            <div className={`col-xs--7 ${overviewDataSectionClassName} overview-data--grey`}>
                                <Ribbon color="grey" width="30px" />
                                <div className="overview-data__title">
                                    <h4 className="ace-h4">{translate('summary_view.header.service_data')}</h4>
                                    <HoverIcon
                                        icon="pen"
                                        isDisabled={isMemberFormDisplayed || isVehicleFormDisplayed}
                                        hoverText={translate('summary_view.hover_text.to_edit')}
                                        isInvisible={isInvoiceSubmissionSubmitted && !isInvoiceSubmissionStatusRequest}
                                        onClick={() => changeView({
                                            invoiceSubmissionViewState: invoiceSubmissionViews.FORM,
                                            formAnchor: formAnchors.SECOND_FORM,
                                        })}
                                    />
                                </div>
                                <div className="overview-data__content--block">
                                    <SummaryDetailRow label={translate('summary_view.data_row_label.main_service')}>
                                        <SummaryDataCell>
                                            {mainServices
                                                .map(mainService => getMainServiceNameForType(mainService, translate))
                                                .filter(mainServiceName => !!mainServiceName)
                                                .join(', ')}
                                        </SummaryDataCell>
                                    </SummaryDetailRow>
                                    <SummaryDetailRow label={translate('summary_view.data_row_label.main_service_comment')}>
                                        <SummaryDataCell>{mainServicesComment || '-'}</SummaryDataCell>
                                    </SummaryDetailRow>
                                    {isTowingSelected && (
                                        <Fragment>
                                            <SummaryDetailRow
                                                label={`${translate('summary_view.data_row_label.towing_location')} ${translate('global.eaf_main_service.towing')}`}
                                            >
                                                <SummaryDataCell>
                                                    {translate(`global.destination_options.${towingDestinationType.toLowerCase()}`) || ''}
                                                </SummaryDataCell>
                                            </SummaryDetailRow>
                                            <SummaryDetailRow label={translate('summary_view.data_row_label.address')}>
                                                <SummaryDataCell>{towingDestinationAddress || '-'}</SummaryDataCell>
                                            </SummaryDetailRow>
                                        </Fragment>
                                    )}
                                    {isPickUpSelected && (
                                        <Fragment>
                                            <SummaryDetailRow
                                                label={`${translate('summary_view.data_row_label.towing_location')} ${translate('global.eaf_main_service.pickup')}`}
                                            >
                                                <SummaryDataCell>
                                                    {translate(`global.destination_options.${pickUpDestinationType.toLowerCase()}`) || ''}
                                                </SummaryDataCell>
                                            </SummaryDetailRow>
                                            <SummaryDetailRow label={translate('summary_view.data_row_label.address')}>
                                                <SummaryDataCell>{pickUpDestinationAddress || '-'}</SummaryDataCell>
                                            </SummaryDetailRow>
                                        </Fragment>
                                    )}
                                    <SummaryDetailRow label={translate('summary_view.data_row_label.additional_services')}>
                                        {filteredAdditionalServices && !!filteredAdditionalServices.length ? (
                                            filteredAdditionalServices.map(({type}, idx) => (
                                                <SummaryDataCell key={idx}>
                                                    {translate(`global.additional_services.${type.toLowerCase()}`)}
                                                </SummaryDataCell>
                                            ))
                                        ) : (
                                            <SummaryDataCell>-</SummaryDataCell>
                                        )}
                                    </SummaryDetailRow>
                                    {!!additionalServices.length && additionalServicesComment && (
                                        <SummaryDetailRow
                                            label={translate('summary_view.data_row_label.additional_service_comment')}
                                        >
                                            <SummaryDataCell>
                                                {additionalServicesComment}
                                            </SummaryDataCell>
                                        </SummaryDetailRow>
                                    )}
                                    {attachments && !!attachments.length && (
                                        <section className="overview-data-attachments">
                                            <h6 className="ace-h6 overview-data-attachments__title">
                                                {translate('summary_view.title.attachments')}
                                            </h6>
                                            <div className="overview-data-attachments__content">
                                                {attachments.map(({fileName, id}) => {
                                                    return (
                                                        <div key={id} className="overview-data-attachments__content-item">
                                                            <div className="overview-data-attachments__content-icon-wrapper">
                                                                <Icon
                                                                    dimension="md"
                                                                    iconName="attachment"
                                                                />
                                                            </div>
                                                            <p className="overview-data-attachments__content-text">
                                                                {fileName}
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </section>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-start-xs--8 col-xs--4">
                        {/* sticky right side panel */}
                        <div className="overview-price__wrapper">
                            <div className={overviewPriceClassName}>
                                <div className="overview-price__left-shadow">
                                    <div className="overview-price__left-shadow--before" />
                                </div>
                                <div className="ace-bottom-margin--sm">
                                    <h6 className="ace-h6">{translate('summary_view.text.order')}<br />
                                        <span>{name}</span>
                                    </h6>
                                    {!!fallbackSystemId && (
                                        <p>{fallbackSystemId}</p>
                                    )}
                                    <p className="overview-price__date">{startDateString}</p>
                                </div>
                                <div className="overview-price__member-info">
                                    <p className="overview-price__member-info-title">
                                        {translate('summary_view.text.member')} {membershipNumber || '-'}
                                    </p>
                                    <p className="overview-price__member-info-name">{memberFullName || '-'}</p>
                                </div>
                                <div className="overview-price__services-info">
                                    <div className="ace-bottom-margin--sm">
                                        <p className="overview-price__services-info-title">
                                            {translate('summary_view.text.services')}
                                        </p>
                                        <div className="overview-price__services-info-list">
                                            {mainServices.filter(({subtotal}) => (subtotal !== 0 && subtotal !== ''))
                                                .map(({type, subtotal, isPrincipalService}) => {
                                                    let capped = false;
                                                    if (billingType === BillingTypes.TIME
                                                        && type === eafMainServices.OPENING
                                                        && subtotal >= openCarMaxPrice
                                                        && !isForeignInsurance
                                                    ) {
                                                        capped = true;
                                                    }
                                                    const mainServiceText = isEmptyTrip
                                                        ? translate('global.eaf_main_service.empty_trip')
                                                        : getMainServiceNameForType({
                                                            type,
                                                            isPrincipalService,
                                                        }, translate);
                                                    return (
                                                        <div
                                                            key={mainServiceText}
                                                            className="overview-price__services-info-list-item"
                                                        >
                                                            <p className="overview-price__services-info-text">
                                                                {mainServiceText}
                                                            </p>
                                                            <div className="overview-capped-price-container">
                                                                {!!capped && (
                                                                    <Tooltip
                                                                        className="overview-capped-price__tooltip"
                                                                        message={`${translate('summary_view.tooltip_message.price_for_vehicle')} ${openCarMaxPrice} ${translate('summary_view.tooltip_message.not_exceed')}`}
                                                                    >
                                                                        <img
                                                                            className="overview-price__internal-invoice-icon overview-price__internal-invoice-icon--caped-price"
                                                                            src={InfoIcon}
                                                                            alt=""
                                                                        />
                                                                    </Tooltip>
                                                                )}
                                                                <p
                                                                    className={`overview-price__services-info-text ${capped ? 'overview-price__services-info-text--caped-price' : ''}`}
                                                                >
                                                                    {convertNumberToCurrency(subtotal)} €
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                            {!!additionalServices
                                            && additionalServices.filter(({subtotal}) => subtotal !== 0)
                                                .map(({type, subtotal}, idx) => {
                                                    return (
                                                        <div
                                                            key={idx}
                                                            className="overview-price__services-info-list-item"
                                                        >
                                                            <p className="overview-price__services-info-text">
                                                                {translate(`global.additional_services.${type.toLowerCase()}`)}
                                                            </p>
                                                            <p className="overview-price__services-info-text">
                                                                {convertNumberToCurrency(subtotal)} €
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                    <div className="overview-price__services-info-summary">
                                        <p className="overview-price__services-info-text">
                                            <b>{translate('summary_view.text.net')}</b>
                                        </p>
                                        <p className="overview-price__services-info-net-price">
                                            {convertNumberToCurrency(total)} €
                                        </p>
                                    </div>
                                </div>
                                <div className="overview-price__internal-invoice">
                                    <div className="overview-price__internal-invoice-text-wrapper">
                                        <p className="overview-price__internal-invoice-text">
                                            {translate('summary_view.text.internal_invoice')}
                                        </p>
                                        <Tooltip
                                            message={translate('summary_view.tooltip_message.internal_invoice')}
                                            position="right"
                                        >
                                            <img
                                                className="overview-price__internal-invoice-icon"
                                                src={InfoIcon}
                                                alt=""
                                            />
                                        </Tooltip>
                                    </div>
                                    <Input
                                        name="externalDocumentNumber"
                                        label={translate('summary_view.input_label.enter_if_necessary')}
                                        value={externalDocumentNo}
                                        onChange={this.onExternalDocumentNoChange}
                                        isDisabled={isInvoiceIssued
                                            || (isInvoiceSubmissionSubmitted && !isInvoiceSubmissionStatusRequest)}
                                        additionalAttributes={{maxLength: '35'}}
                                        error={errors.externalDocumentNumber
                                            && translate(`global.validation_messages.${errors.externalDocumentNumber}`)}
                                    />
                                </div>
                                {isSpecificEmptyTrip && !isInvoiceIssued && (
                                    <div className="overview-price__member-certificate">
                                        <div className="overview-price__member-certificate-checkbox-container">
                                            <Checkbox
                                                isChecked={isInvoiceSubmissionSubmitted
                                                    || isCheckedMemberCertificate}
                                                onChange={this.onChangeMemberCertificate}
                                                isDisabled={isInvoiceSubmissionSubmitted
                                                    || isInvoiceSubmissionRequestedSubService}
                                            />
                                        </div>
                                        <p className="overview-price__member-certificate-text">
                                            {translate('summary_view.text.member_certificate')}
                                        </p>
                                    </div>
                                )}
                                {status !== eafStatuses.BOOKED && (
                                    <div className="overview-price__submit-button-container">
                                        <ButtonPrimary
                                            label={buttonText}
                                            name="overviewPriceSubmitBtn"
                                            isDisabled={this.shouldDisableSubmitButton()}
                                            onClick={this.onSubmitButtonClick}
                                            icon={isInvoiceIssued ? 'download--white' : null}
                                        />
                                    </div>
                                )}
                                {isInvoiceSubmissionSubmitted && !isInvoiceSubmissionStatusRequest && (
                                    <div className="download-proof-button-container">
                                        <ButtonSecondary
                                            label={translate('summary_view.button_label.download_proof')}
                                            name="downloadProofOfService"
                                            onClick={() => downloadProofOfService({invoiceSubmissionId})}
                                        />
                                    </div>
                                )}
                                {isFollowUpInvoiceSubmissionAllowed && (
                                    <div className="overview-price__back-to-application-link-wrapper">
                                        <span
                                            className="ace-link-m"
                                            onClick={this.createFollowUpAssignment}
                                        >
                                            {translate('summary_view.link_text.follow_up_assignment')}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ace-grid__row">
                    <div className={`col-xs--4 col-start-xs--5 ${overviewPriceSaveClassName}`}>
                        <ButtonSecondary
                            label={saveButtonText}
                            name="summarySaveAndExitBtn"
                            onClick={this.onSaveButtonClick}
                            isDisabled={isMemberFormDisplayed
                                || isVehicleFormDisplayed
                                || invoiceSubmissionDataState === InvoiceSubmissionDataStates.LOADING}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        submitPopUpStatus: state.invoiceSubmission.submitPopUpStatus,
        dataStatus: state.invoiceSubmission.invoiceSubmissionDataState,
        invoiceSubmissionMessages: state.messagesReducers.invoiceSubmissionMessages,
        openCarMaxPrice: state.invoiceSubmission.openCarMaxPrice,
        userAccount: state.userManagement.user,
    };
};

const mapDispatchToProps = dispatch => ({
    changeView: payload => dispatch({
        type: invoiceSubmissionActionTypes.CHANGE_INVOICE_SUBMISSION_VIEW_STATE,
        payload,
    }),
    createFollowUpAssignment: payload => dispatch({
        type: invoiceSubmissionActionTypes.CREATE_FOLLOW_UP_INVOICE_SUBMISSION,
        payload,
    }),
    updateInvoiceSubmission: payload => dispatch({
        type: invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION,
        payload,
    }),
    downloadProofOfService: payload => dispatch({
        type: invoiceSubmissionActionTypes.START_DOWNLOAD_PROOF_OF_SERVICE_FLOW,
        payload,
    }),
    submitInvoiceSubmission: payload => dispatch({
        type: invoiceSubmissionActionTypes.SUBMIT_INVOICE_SUBMISSION,
        payload,
    }),
    closeSubmitPopUp: () => dispatch({
        type: invoiceSubmissionActionTypes.CLOSE_SUBMIT_POPUP,
    }),
    deleteErrors: () => dispatch({
        type: invoiceSubmissionActionTypes.STORE_SUBMIT_POPUP_STATUS,
        payload: {submitPopUpStatus: submitPopUpStatuses.CLOSED},
    }),
    downloadPdfAndCloseSubmitPopUp: payload => dispatch({
        type: invoiceSubmissionActionTypes.DOWNLOAD_PDF_AND_CLOSE_SUBMIT_POPUP,
        payload,
    }),
    downloadBCReceiptPDF: payload => dispatch({
        type: invoiceSubmissionActionTypes.DOWNLOAD_BC_RECEIPT_PDF,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslations(SummaryView)));
